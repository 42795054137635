.MuiToolbar-root.MuiToolbar-regular {
  background: black;
}
.MuiToolbar-regular {
  height: 4em;
}
.header-link {
  max-width: fit-content !important;
}

/* Drawer styles */
.MuiDrawer-paper {
  background-color: #2B35AF !important;
  min-height: 100vh !important;
}

.MuiListItem-root.MuiListItem-button:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.MuiListItem-root {
  padding: 15px 20px !important;
}

.MuiListItemText-primary {
  font-weight: bold !important;
  font-size: 1.1rem !important;
}

@media (max-width: 959px) {
  .header-tabs, .header-btn {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .header-logo {
    width: 30% !important;
    padding: 0px !important;
    /* margin-left: 10px !important; */
  }
  .header-link {
    margin-left: 0px !important;
    padding: 6px 5px !important;
    font-size: 0.875rem !important;
  }
  .header-logo-image {
    height: 7em !important;
    overflow: inherit !important;
    width: 10em !important;
    /* margin-left: 5px !important; */
  }
  .header-btn {
    margin-right: 10px !important;
    width: 85px !important;
    margin-left: 10px !important;
    height: 35px !important;
    font-size: 12px !important;
    /* background: rgba(24, 12, 33, 1) !important;
    border: 2px solid rgba(161, 90, 246, 1) !important;
    border-radius: 25px !important; */
  }
}

@media (min-width: 1920px) {
  .cardsmall-heading {
    font-size: 1.5rem !important;
  }
  .cardsmall-date {
    font-size: 1rem !important;
  }
  .cardsmall-desc {
    font-size: 1rem !important;
    max-height: 9.5rem !important;
    margin-top: 30px !important;
  }
  .cardsmall-img {
    height: 60% !important;
    margin-left: 0px !important;
  }
  .cardsmall-card {
    /* height: 70% !important; */
    padding-bottom: 2em !important;
    padding: 20px !important;
  }
  .cardsmall-main{
    column-gap: 110px !important;
  }
  .MuiToolbar-regular {
    height: 5em;
  }
  .header-logo-image {
    height: 12em !important;
    width: 20em !important;
  }
  .header-link {
    font-size: 1.25rem !important;
  }
  .header-tabs {
    margin-top: 0px !important;
  }
  .header-btn {
    width: 160px !important;
    height: 50px !important;
    font-size: 1.5rem !important;
  }
}

@media (max-width: 359px) {
  .header-logo-image {
    height: 7em !important;
    /* margin-left: 5px !important;/ */
  }
  .header-tabs {
    margin-top: 0px !important;
    height: auto !important;
  }
  .header-link {
    font-size: 12px !important;
  }
  .header-btn {
    margin-right: 10px !important;
    width: 75px !important;
    margin-left: 9px !important;
    height: 30px !important;
    font-size: 10px !important;
  }
  .MuiToolbar-regular {
    min-height: 50px !important;
    height: 2em !important;
  }
}

/* Desktop navigation container */
@media (min-width: 960px) {
  .MuiTabs-root.header-tabs {
    margin-left: auto;
  }
}
