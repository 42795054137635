.sidebar {
  flex: 1;
  min-width: 15%;
  max-width: 15%;
  height: calc(100vh);
  background: linear-gradient(180deg, #a15af6 -0.12%, #a309d9 99.52%);
  position: fixed;
  bottom: 0px;
  z-index: 999;
}

.sidebarWrapper {
  padding: 20px;
  color: white;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 22px;
  color: white;
  padding-bottom: 50px;
  margin-top: 0px;
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 120%;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: #ffffff1a;
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}

@media (min-width: 1920px) {
  .sidebarTitle {
    font-size: 36px !important;
  }
  .sidebarListItem {
    padding: 20px !important;
  }
  .sidebarIcon {
    font-size: 30px !important;
  }
  .link {
    font-size: 24px !important;
  }
}
