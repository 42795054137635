.userList {
  flex: 4;
}

.userListUser {
  display: flex;
  align-items: center;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.userListDelete {
  color: red;
  cursor: pointer;
}

@media (min-width: 1920px) {
  .admin-price-heading,
  .card-main-heading {
    font-size: 3rem !important;
  }
  .admin-price-text {
    font-size: 1.25rem !important;
  }
  .card-main-grid {
    min-width: 420px !important;
    max-width: 420px !important;
    height: 510px !important;
  }
  .card-main-text {
    font-size: 1.5rem !important;
  }
  .card-main-rupee {
    font-size: 2.25rem !important;
  }
}
