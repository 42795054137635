.bar {
  width: 90%;
  height: 50px;
  /* background-color: #59acde; */
  display: flex;
  /* z-index: 999; */
}
.barTop {
  height: 100%;

  /* padding: 0px; */
  display: flex;

  align-items: flex-start;
  justify-content: center;
}
.barRight {
  /* display: flex; */
  flex-direction: row;
  align-items: flex-end;
}
