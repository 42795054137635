.chart {
  margin: 20px;
  padding: 20px;

  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.chartTitle {
  margin-bottom: 20px;
}

@media (min-width: 1920px) {
  .chartTitle {
    font-size: 2rem !important;
  }
}
