.home {
  flex: 4;
  margin-top: 25px;
}

.homeWidgets {
  display: flex;
  margin: 20px;
}
@media (min-width: 1536px) {
}
