@media (min-width: 1920px) {
  .coumpan-heading {
    font-size: 3rem !important;
  }
  .c-text {
    font-size: 28px !important;
  }
  .coupan-price-text {
    right: 5em !important;
  }
  .coupan-price-form {
    padding-left: 4.5em !important;
    width: 50% !important;
  }
  .coupan-discount-form {
    width: 50% !important;
  }
  .genrate-code-btn {
    padding: 15px !important;
    width: 20% !important;
    font-size: 22px !important;
    margin-left: 6em !important;
  }
  .coupan-submit-btn {
    width: 23% !important;
    padding: 34px !important;
    margin-left: 2em !important;
    font-size: 24px !important;
  }
  .coupan-code-text {
    margin-left: 2em !important;
  }
}
.swal2-container {
  z-index: 9999 !important; /* Increase the z-index value */
}
