.topbar {
  width: 100%;
  height: 78px;

  background-color: white;
  position: sticky;
  top: 0;
  padding-right: 1.2em;
  z-index: 999;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.topRight {
  display: flex;
  align-items: center;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}

.topIconBadge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.name {
  cursor: pointer;
}

@media (min-width: 1920px) {
  .admin-logout-btn{
    width: 120px !important;
    font-size: 18px !important;
  }
}