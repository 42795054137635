.hero {
  background-image: url("../../assets/back.png");
  background-size: 145% 100%;
  animation: flow 10s ease-in-out infinite alternate;
}

@keyframes flow {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
input[type="file"] {
  display: none;
}

.images {
  width: 35vw;
  border-radius: 10px;
  /* margin-right: 50px; */
}

@media screen and (max-width: 599px) {
  .images {
    width: 90vw;
  }
}

.btn-grad {
  background: linear-gradient(90deg, #a15af6, #a309d9);
}
.btn-grad {
  margin: 8px;
  padding: 12px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 0px;
  /* display: block; */

  display: inline-block;

  /* margin-right: 250px; */
  cursor: pointer;
  z-index: 20;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #000;
  text-decoration: none;
  z-index: 200;
  box-shadow: 0 0 20px #eee;
}

@keyframes flow {
  0% {
    background-position: 0 50%;
  }
  /* 50% {
    background-position: 100% 50%;
  } */
  100% {
    background-position: 0 50%;
  }
}

.loginInput3 {
  position: relative;
  border-bottom: 2px solid rgb(65, 62, 62);
  margin: 30px 0;
}

.loginInput2 {
  position: relative;
  border-bottom: 2px solid rgb(0, 0, 0, 0.25);
  /* margin: 30px 0; */
}

.loginInput2 input {
  width: 100%;
  padding: 0 5px;
  height: 60px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}

.loginInput2 label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #f26d21;
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.loginInput2 span ::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 2px;
  background: #f26d21;
  transition: 0.5s;
}

.loginInput2 input:focus ~ label,
.loginInput2 input:valid ~ label {
  top: -5px;
  color: transparent;
}
.loginInput2 input:focus ~ span::before,
.loginInput2 input:valid ~ span::before {
  width: 100%;
}

@media (min-width: 1536px) {
  .cms-text {
    font-size: 22px !important;
  }
  .cms-h5 {
    font-size: 2rem !important;
  }
  .cms-grid1-heading {
    font-size: 3rem !important;
  }
  .cms-grid-btn {
    font-size: 16px !important;
    padding: 28px !important;
  }
  .cms-grid-btn2 {
    padding: 22px !important;
  }
  .cms-section-4{
    height: 500px!important;
  }
  .cms-section-2{
    height: 490px!important;
  }
}
