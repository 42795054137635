.loginSection {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0px;
  min-height: 100vh;
  background-color: rgb(223, 209, 209, 1);
}

.loginImage {
  position: absolute;
  display: flex;
  /* align-items: center; */
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  top: 120px;
  /* opacity: 1; */
  background-color: rgb(223, 209, 209, 1);
  background-repeat: no-repeat;
  background-size: auto;
}

.loginCard {
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 20%;

  /* opacity: 0.5; */
  background-color: rgb(223, 209, 209, 0.5);
  /* justify-content: center; */
  align-items: center;
  margin-right: 20px;
  left: 50px;
  margin: 0px 100px;
  padding: 30px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border: none;
}

.MuiInput-colorSecondary.MuiInput-underline::before {
  border-bottom-color: #836af5!important;
}

.MuiInput-colorSecondary.MuiInput-underline::after {
  border-bottom-color: #8a71ff!important;
}

.MuiFormLabel-colorSecondary.Mui-focused {
  color: #8a71ff!important;
}

.loginInput {
  position: relative;
  border-bottom: 2px solid #f26d21;
  margin: 30px 0;
}

.loginInput input {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}

.loginInput label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #f26d21;
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.loginInput span ::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 2px;
  background: #f26d21;
  transition: 0.5s;
}

.loginInput input:focus ~ label,
.loginInput input:valid ~ label {
  top: -5px;
  color: transparent;
}
.loginInput input:focus ~ span::before,
.loginInput input:valid ~ span::before {
  width: 100%;
}

.button button {
  width: 100%;
  height: 50px;
  border: 1px solid;
  background: #f26d21;
  border-radius: 25px;
  font-size: 18px;
  color: #e9f4fb;
  font-weight: 700;
  cursor: pointer;
  outline: none;
}

.button button :hover {
  border-color: #2691d9;
  transition: 0.5s;
}
.forget-link-admin {
  margin-top: 10px;
  margin-left: auto !important;
  margin-right: 10px !important;
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
@media (min-width: 1920px) {
  .admin-login-page{
    /* margin-top: 80px!important; */
  }
  .admin-login-heading {
    font-size: 4rem !important;
  }
  .username-field,
  .email-field {
    margin-bottom: 10px !important;
  }
  .username-field label,
  .email-field label,.MuiInputBase-input {
    font-size: 1.25rem !important;
  }
  label + .MuiInput-formControl {
    /* margin-top: 40px !important; */
  }
  .admin-login-btn {
    height: 80px !important;
    font-size: 2rem !important;
  }
  .forget-link-admin {
    font-size: 1.5rem!important;
  }
}
