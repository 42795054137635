.newCard:hover {
  background: blue;
  color: black;
}

@media (min-width: 1920px) {
  .coopan-main-card {
    /* max-width: 550px !important; */
    height: 100% !important;
  }
  .discount-price {
    font-size: 2rem !important;
  }
  .discount-text {
    font-size: 1.25rem !important;
  }
  .coupan-heading {
    font-size: 3.5rem !important;
  }
  .create-coupan-btn {
    padding: 10px !important;
    font-size: 24px !important;
    min-width: 160px !important;
  }
  .coupan-code {
    font-size: 2rem !important;
  }
  .coupan-grid {
    height: 4em !important;
  }
  .coupan-model {
    width: 1000px !important;
    height: 700px !important;
  }
  .admin-copun-card{
    width: 50% !important;
  }
  .crd-action{
    padding: 16px !important;
  }
  .card-delete-coupon{
    /* padding: 0px 24px 24px !important */
  }
  .delete-coupon-icon{
    font-size: 2.5rem !important;
  }
}
